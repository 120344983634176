/******* Custom Font Size: iqCustomFont **********/
.theme-fs-sm {
  font-size: 14px;
}
.theme-fs-md {
  font-size: 16px;
}
.theme-fs-lg {
  font-size: 18px;
}
@media (max-width: 1601px) {
  .theme-fs-sm {
    font-size: 13px;
  }
  .theme-fs-md {
    font-size: 14px;
  }
  .theme-fs-lg {
    font-size: 16px;
  }
}
