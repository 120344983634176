#zuck-modal-content .story-viewer .slides .item {
    background: rgba(0,0,0,.2) !important;
    backdrop-filter: blur(10px);
}
#zuck-modal {
    backdrop-filter: blur(10px);
}
.storiesWrapper{
    max-width: unset !important;
    margin: unset !important
}