/* Level Area */
.t-level-area {
    background-color: #F7F7F7;
    min-height: 8.5rem;
}

.flex-1 {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
}

.levelChild {
    flex: 1;
    i {
        background-color: #ddd;
        color: #fff;
        height: 1.5rem;
        width: 1.5rem;
    }
}

.levelChild {
    &:nth-child(4) {
        color: #E83E8C;
        i {
            background-color: #E83E8C;
        }
    }
}


/* Funnel Area */
.userFunnel {
    font-size: 1rem;
    color: #606375;

    .bg-pink {
        background-color: #C71869;
    }
}


.funnelCurrentUser {
    &:nth-child(2) {
        width: 95%;
        background-color: #E83E8C;

        h1, h6{color:#fff;}
    }

    &:nth-child(3) {
        width: 90%;
    }

    &:nth-child(4) {
        width: 85%;
    }

    &:nth-child(5) {
        width: 80%;
    }

    &:nth-child(6) {
        width: 75%;
    }
}


.reUser {
    font-size: 1rem;

    .statics {
        background-color: #F5F5F5;

        i {
            font-style: normal;
        }
    }
}

.reUserStatics {
    &:nth-child(1) {
        border: 4px solid #E83E8C;
    }

    &:nth-child(2) {
        border: 4px solid #34C38F;
    }

    &:nth-child(3) {
        border: 4px solid #F1B44C;
    }
}


.downlodReport {
    .reportIocn {
        font-size: 1.3rem;
    }
}

.downlodReportRow {
    span {
        width: 25%;
    }
}

.downlodReport {
    select {
        min-height: 3rem;
        border: 1px solid #ddd;
        border-radius: 0.5rem;
        padding: 0 1rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        min-height: 3rem;
    }
}

.downlodReport {
    span {
        &:before {
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-family: "Font Awesome 6 Pro";
            font-weight: 900;
            content: "\f078";
            position: absolute;
            top: 0.6rem;
            right: 1rem;
        }
    }
}

.download-btn {
    button {
        background-color: #214180;
        color: #fff;
        min-height: 3rem;
    }
}

// .downlodReport select:active,
// .downlodReport select:focus,
// .downlodReport select:focus-visible{
//     border:0;
// }


@media screen and (max-width: 768px) {

    .funnelCurrentUser:nth-child(2),
    .funnelCurrentUser:nth-child(3),
    .funnelCurrentUser:nth-child(4),
    .funnelCurrentUser:nth-child(5),
    .funnelCurrentUser:nth-child(6) {
        width: 100%;
    }

    .funnelStatics {
        width: 100%;
    }

    // .funnelStatics .d-flex {
    //     flex: 1;
    // }

    .levelChild {
        i {
            transform: rotate(270deg);
        }
    }

    .downlodReportRow span,
    .download-btn,
    .download-btn button {
        width: 100% !important;
    }
}