.custom-TreeView {
  margin: 0;
  padding: 0;
  font-size: $font-size-base * 0.94;
  overflow-y: auto;
  & > li {
    background-color: rgba(var(--bs-primary-rgb), 0.1);
    margin-bottom: 0.8rem;
    border-radius: 0.6rem;

    & > .tree-node {
      padding: 0.6rem 2rem 0.6rem 2rem;
      cursor: pointer;

      .down-arrow-icon {
        top: 0.7rem;
      }
      .folder-icon {
        top: 0.7rem;
      }
      &--expanded {
        &::before {
          height: calc(100% - 1.4rem);
        }
        &:after {
          top: calc(100% - 0.4rem);
        }
      }
    }
  }

  .tree-node-group {
    padding: 0;
    padding-left: 0.7rem;
    list-style-type: none;
    &--expanded {
      padding-bottom: 0.3rem;
    }
  }
  .tree-node {
    padding: 0.4rem 2rem 0.4rem 2rem;
    cursor: pointer;
    &::after,
    &::before {
      content: "";
      position: absolute;
      background-color: rgba(var(--bs-primary-rgb), 0.5);
      transition: $transition-base;
      left: 1rem;
    }
    &::after {
      height: 1px;
      top: calc(100% - 0.4rem);
      animation-delay: 0.1s;
      width: 0;
    }
    &::before {
      height: 0;
      top: 1rem;
      width: 1px;
    }
    &--expanded {
      color: var(--bs-primary);
      .down-arrow-icon {
        rotate: -180deg;
      }
      &::after {
        width: 0.6rem;
      }
      &::before {
        height: calc(100% - 1.4rem);
      }
    }
  }
  .tree-node__leaf {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    .folder-icon {
      top: 0.35rem;
    }
  }
  .down-arrow-icon {
    right: 0.6rem;
    top: 0.4rem;
    transition: $transition-base;
    svg {
      display: block;
    }
  }
  .folder-icon {
    left: 0.45rem;
    top: 0.52rem;
    transition: $transition-base;
    svg {
      display: block;
    }
  }
  .tree-leaf-list-item {
    list-style-type: none !important;
    // margin: 0 !important;
    margin-left: 0 !important;

    .tree-node__leaf {
      .folder-icon {
        left: 0.6rem;
        // top: 0.4rem;
      }
      &:hover,
      .tree-node--focused {
        color: var(--bs-primary);
      }
    }
  }
  li {
    position: relative;
  }
}
