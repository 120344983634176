$btn-icon-width: 3.5rem !default;
$btn-icon-height: 2.5rem !default;

$btn-icon-width-xs: 1.5rem !default;
$btn-icon-height-xs: 1.5rem !default;

$btn-icon-width-sm: 1.875rem !default;
$btn-icon-height-sm: 1.875rem !default;

$btn-icon-width-lg: 3rem !default;
$btn-icon-height-lg: 3rem !default;
